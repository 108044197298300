import React, { useEffect, useState } from "react"

import useAdmin from "../../../hooks/useAdmin"
import {
  Wrapper,
  Container,
  HeadingTitle,
  HeadingWrapper,
  Separator, FormSelect, InputWrapper, WrapperMenu, MessageInput,
  WrapperTitle, WrapperListMenu, HeadingCat, WrapperCollection, InputWrapperFull
} from "./StyledAdminHome"

import { AiOutlineCheck } from "react-icons/ai"
import { uuid } from "uuidv4"
import { Label } from "../../Account/Profile/StyledAccountProfile"
import { ButtonSubmit } from "../Collections/StyledAdminCollections"

export default ({ setSuccess }) => {
    const { getBlockHome, getCollections, getProductByCollection, updateHomePage } = useAdmin()
    const [listDataHome, setListDataHome] = useState([])
    const [allCollections, setAllCollections] = useState([])
    const [productList, setProductList] = useState([])
    const [isLoading, setLoading] = useState(false)

    const handleSetProductList = (list) => {
      let collection = list.find(item => item.data.collection)
      getProductByCollection(collection.data.collection).then((res) => setProductList(res))
    }

    useEffect(() => {
      if (listDataHome.length < 1) {
        getBlockHome()
          .then((res) => {
            setListDataHome(res)
            return res
          })
          .then(res => handleSetProductList(res))
      }
      if (allCollections.length < 1) {
        getCollections().then((res) => setAllCollections(res))
      }
    }, [])

    const handleChange = (e, blockId, attribute) => {
      e.preventDefault()
      setListDataHome(listDataHome.map((block, id) => {
        if (id === blockId) {
          block.data[attribute] = e.target.value
        }
        return block
      }))
      getProductByCollection(e.target.value).then((res) => setProductList(res))
    }

    const handleChangeProduct = (e, blockId, productOrder) => {
      e.preventDefault()
      setListDataHome(listDataHome.map((block, id) => {
        if (id === blockId) {
          let productListFilter = block.data.listProducts
          productListFilter[productOrder] = { id: e.target.value }
          block.data.listProducts = productListFilter
        }
        return block
      }))
    }

    const handleSaveFeaturedCollection = (e) => {
      e.preventDefault()
      setLoading(true)
      let data = [...listDataHome]
      updateHomePage(data).then((res) => {
        setLoading(false)
        setSuccess("Vos modifications ont été sauvegardées")
      })
    }

    const renderBlock = (block, idBlock) => {
      return (
        <WrapperMenu key={block.id + block.type}>
          <HeadingCat>{block.data.title}</HeadingCat>
          <WrapperCollection>
            <InputWrapper style={{ marginBottom: "15px" }}>
              <Label>Collection</Label>
              <FormSelect
                value={listDataHome[idBlock].data.collection}
                onChange={(e) => handleChange(e, idBlock, "collection")}>
                {allCollections && allCollections.map((item, index) => (
                  <option key={item.id} value={item.id}>{item.data.name}</option>
                ))}
              </FormSelect>
            </InputWrapper>
            {block.data.listProducts.length > 0 && block.data.listProducts.map((product, idProduct) => (
              <InputWrapper key={block.id + block.data.id + idProduct} style={{ marginBottom: "15px" }}>
                <Label>Bijou {idProduct + 1}</Label>
                <FormSelect
                  value={listDataHome[idBlock].data.listProducts[idProduct].id}
                  onChange={(e) => handleChangeProduct(e, idBlock, idProduct)}>
                  {productList.map((item, index) => (
                    <option key={item.id}
                            value={item.id}>{`${item.ref} - ${item.attributeType} ${item.attributeGem} ${item.attributeMetal}`}</option>
                  ))}
                </FormSelect>
              </InputWrapper>
            ))}
            <InputWrapperFull>
              <Label>Description</Label>
              <MessageInput
                type={"text"}
                value={listDataHome[idBlock].data.description}
                onChange={e => handleChange(e, idBlock, "description")}
              />
            </InputWrapperFull>
          </WrapperCollection>
        </WrapperMenu>
      )
    }

    return (
      <Wrapper>
        <Container>
          <HeadingWrapper>
            <WrapperTitle>
              <HeadingTitle>Modification de la page d'accueil</HeadingTitle>
            </WrapperTitle>
            <Separator/>
          </HeadingWrapper>
          <WrapperListMenu>
            {listDataHome && listDataHome.length > 0 && listDataHome.map((block, idBlock) => (
              renderBlock(block, idBlock)
            ))}
          </WrapperListMenu>
          {!isLoading && (
            <ButtonSubmit className={"visible"} onClick={handleSaveFeaturedCollection}>
              <AiOutlineCheck/>
              <span style={{ marginLeft: "1rem" }}>Sauvegarder</span>
            </ButtonSubmit>
          )}
        </Container>
      </Wrapper>
    )
  }
