import styled from "@emotion/styled"
import { breakpoints, colors } from "../../styles"

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
`

export const Container = styled.div`
  color: #234361;
  font-family: Lato;
  max-width: 550px;
  flex: 1;
  padding-top: 10px;
  padding-bottom: 2rem;
  align-items: left;
  flex-direction: column;
  display: flex;
  background: white;
  border-radius: 5px;
  transition-duration: 1s;
  margin-left: 30px;
`

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  padding: .5rem 1.5rem;
`

export const WrapperTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: .5rem;
`

export const HeadingTitle = styled.span`
  font-size: 1.1rem;
`

export const Separator = styled.div`
  width: 100%;
  margin: auto;
  height: 1px;
  background: #f5f5f5;
  margin-top: 5px;
  margin-bottom: 5px;
`

export const WrapperListCollections = styled.div`
  width: 100%;
  min-width: 550px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 1rem 1.5rem;
`

export const CollectionWrapper = styled.div`
  margin: 0 1rem;
  margin-bottom: 1rem;
  position: relative;
  cursor: pointer;
  text-align: center;
`

export const CollectionImageWrapper = styled.div`
 
`

export const CollectionImage = styled.img`
  max-height: 5rem;
`

export const ButtonNewSlider = styled.button`
  font-weight: 600;
  color: #0F56B3;
  background: #E2EDFC;
  display: flex;
  align-items: center;
  font-family: Lato;
  text-transform: uppercase;
  font-size: .7rem;
  padding: .3rem .5rem;
  border-radius: .3rem;
  cursor: pointer;
  transition: all 0.3 ease;
  &:hover {
    background: #0F56B3;
    color: white;
  }
`

export const WrapperListInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
`

export const SlideImagePreview = styled.div`
  max-height: 5rem;
  margin-bottom: 1rem;
`

export const ImagePreview = styled.img`
  height: 100%;
`

export const InputWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
`

export const FormInput = styled.input`
  display: flex;
  width: 100%;
  font-family: Lato;
  font-size: .9rem;
  width: ${props => props.isMobile && "100%"};
  color: #383838;
  outline: none;
  flex-direction: column;
  min-height: 42px;
  border-radius: 5px;
  padding: 0 20px;
  cursor: pointer;
  line-height: 1.1;
  border: 1px solid rgb(56 56 56 / 0.08);
  background-color: #fff;
  box-shadow: 0 0 5px 0px #0000000d;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  &:focus {
    box-shadow: inset 0 0 2px rgba(67, 90, 111, 0.14), inset 0 0 0 1px #579ad9, 0 0 0 3px rgba(16, 112, 202, 0.14);
  };
  &.input-error {
    box-shadow: inset 0 0 2px rgba(67, 90, 111, 0.14), inset 0 0 0 1px #ec4c47a3, 0 0 0 3px #ec4c4714;
  }
`

export const ButtonSubmit = styled.button`
  display: flex;
  margin: auto;
  width: 80%;
  padding: .5rem 1rem;
  visibility: hidden;
  opacity: 0;
  animation-duration: 0.8s;
  font-family: Lato;
  float: right;
  cursor: pointer;
  border: 0;
  background: black;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  color: white;
  outline: none;
  font-size: 1.1rem;
  transition-duration: 0.8s;
  box-shadow: 0px 9px 8px -9px black;
  &:hover{
    transition-duration: 0.2s;
    background: #0F56B3;
    box-shadow: 0px 9px 8px -9px #0F56B3;
  };
  &.visible {
    animation-duration: 0.8s;
    visibility: visible;
    opacity: 1;
  };
  &.full {
    @media (max-width: ${breakpoints.phablet}px) {
      width: 100%;
    };
  }
`

export const CollectionTitle = styled.div`
  margin-top: 1rem;
  font-size: 1rem;
`