import styled from "@emotion/styled"
import { colors } from "../../styles"

export const VariationCard = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 30px;
  position: relative;
`

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  z-index: 10;
  position: absolute;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  color: ${colors.warning};
  font-size: 10px;
  background: #fafafa;
  padding: 10px;
  line-height: 0;
  font-size: 13px;
  font-weight: 100;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  transition-duration: 0.3s;
  &:hover {
    background: ${colors.warning};
    color: white;
  }
`

export const VariationDesc = styled.p`
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
  font-size: .9rem;
`

export const TitleProduct = styled.p`
  font-weight: 300;
  font-size: 1rem;
`
