import React, { useEffect, useState } from "react"
import { Router } from "@reach/router"
import Amplify from 'aws-amplify'
import awsConfig from "../utils/aws-exports"
import CreateProduct from "../components/Admin/CreateProduct"
import Slider from "../components/Admin/Slider"
import Collections from "../components/Admin/Collections"
import MegaMenu from "../components/Admin/MegaMenu"
import EditHome from "../components/Admin/EditHome"
import EditProduct from "../components/Admin/EditProduct"
import NavMenu from "../components/Admin/NavMenu"
import styled from "@emotion/styled"
import useAuth from "../hooks/useAuth"
import { Spinner, toaster } from "evergreen-ui"
import { navigate } from "gatsby"
import { isLoggedIn } from "../utils/auth"

Amplify.configure(awsConfig)

function WrapperRouter({ children }) {
  // Shorthand of <React.Fragment>{children}</React.Fragment>
  return <>{children}</>;
}

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 50px;
`

const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  padding-top: 25vh;
  background: #fafafa;
`

export default () => {
  console.info("ADMIN PAGE RENDER")
  const [dashboard, setDashboard] = useState(false)
  const { currentUser } = useAuth()

  useEffect(() => {
    if (currentUser.role === "admin") {
      setDashboard(true)
    }
    if (currentUser.role === "customer" || !isLoggedIn()) {
      navigate("/")
    }
  }, [currentUser])

  const toggleSuccess = (message) => {
    window.scrollTo(0, 0)
    toaster.success(
      message
    )
  }

  if (dashboard) {
    return (
      <Container>
        <NavMenu setNavigation={(dest) => navigate("/dashboard/" + dest)}/>
        <Router primary={false} component={WrapperRouter} basepath="/dashboard">
          <CreateProduct path="/createProduct" />
          <EditProduct path="/editProduct"/>
          <Slider setSuccess={toggleSuccess} path="/editSlider"/>
          <Collections path="/editCollections"/>
          <MegaMenu setSuccess={toggleSuccess} path="/editMenu"/>
          <EditHome setSuccess={toggleSuccess} path="/editHome"/>
        </Router>
      </Container>
    )
  }

  return (
    <LoaderContainer>
      <Spinner marginX={"auto"} marginY={"auto"} size={38}/>
    </LoaderContainer>
  )
}
