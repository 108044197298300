export const actionAddVariation = product => ({
  type: 'SET_PRODUCT_VARIATION_ADMIN',
  product,
})

export const actionDeleteVariation = listProducts => ({
  type: 'DELETE_PRODUCT_VARIATION_ADMIN',
  listProducts,
})

export const actionSendProductSuccess = () => ({
  type: 'SUCCESS_SEND_PRODUCT_ADMIN',
})


export const actionClearListProducts = () => ({
  type: 'CLEAR_LIST_PRODUCT_ADMIN',
})

export const actionClearSuccess = () => ({
  type: 'CLEAR_SUCCESS_ADMIN',
})

