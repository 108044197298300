import styled from "@emotion/styled"

const FormContainer = styled.div`

  `

const Label = styled.p`
  font-family: Muli;
  font-size: 0.9rem;
  margin-bottom: 8px;
  color: #66778a;
  &.marginR {
    margin-right: 1rem;
  }
`

const StyledInput = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  `

const StyledButton = styled.div` 
    width: 100%;
    display: flex;
    justify-content: center;
  `

const PreviewImage = styled.img`
  max-width: 125px;
`

const TitleForm = styled.div`
  font-weight: 500;
  color: #66778a;
`

export {
  TitleForm,
  Label,
  FormContainer,
  StyledInput,
  StyledButton,
  PreviewImage
}
