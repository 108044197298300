import React, { useState } from "react"
import { Storage } from "aws-amplify"
import { uuid } from "uuidv4"

import state from "./state"
import store from "./store"

import {
  Pane,
  Textarea,
  Autocomplete,
  TextInput,
  FilePicker,
  Button,
  Spinner,
  toaster,
  Heading,
  Checkbox
} from "evergreen-ui"

import {
  ButtonRemoveImage,
  Label,
  PreviewImage,
  WrapperInput,
  WrapperListPreviewImage,
  WrapperPreviewImage
} from "./styles"
import { Separation } from "../../styles"

import {
  initialProduct,
  collectionsList,
  attributeTypeList,
  attributeGemList,
  attributeMetalList,
  attributeSize, attributeGenre
} from "../../shared/ProductMetadata"
import { MdRemoveCircle } from "react-icons/md"

export default ({ createProduct, isVariation, createProductVariation, listCollections }) => {
  const { currentProduct, setProduct, isError, setError } = state({...initialProduct, id: uuid()})
  const [listImages, setListImages] = useState([])
  const [clearInput, setClearInput] = useState(Date.now)
  const [loading, setLoading] = useState(false)
  const [imageLoading, setImageLoading] = useState(false)
  const { addVariation } = store()
  const [singleGem, setSingleGem] = useState(currentProduct.singleGem)

  console.info("FORM RENDER")

  const checkFormError = () => {
    let error = false
    for (let [key, value] of Object.entries(currentProduct)) {
      if (value === "") {
        console.info("ERRROR", key, value)
        error = {
          ...error,
          [key]: "empty"
        }
      }
    }
    return error
  }

  const handleChange = (name, value, checked) => {
    setProduct({
      ...currentProduct,
      [name]: value || checked
    })

    if (isError[name]) {
      setError({
        ...isError,
        [name]: false
      })
    }
  }

  const handleAddVariation = (e) => {
    e.preventDefault()
    let error = checkFormError()
    console.info(currentProduct)
    if (!error) {
      addVariation({
        ...currentProduct,
        images: listImages.map(image => ({id: image.id})),
        preview: listImages,
        singleGem: singleGem,
        id: uuid()
      })
        .then(prevProduct => {
          toaster.success(
            "Nouvelle variation de produit"
          )
          if (prevProduct.singleGem) setProduct({...prevProduct, price: "0"})
          else setProduct({...prevProduct})
          setListImages([])
          setClearInput(Date.now())
        })
    } else setError(error)
  }

  const handleCreateProduct = (e) => {
    e.preventDefault()
    setLoading(true)
    let error = checkFormError()
    if (!error) {
      createProduct({
        ...currentProduct,
        images: listImages.map(image => ({id: image.id})),
        singleGem,
        id: uuid()
      }).then(() => {
        setLoading(false)
        toaster.success(
          "Votre produit à bien été ajouté"
        )
        setProduct(initialProduct)
        setClearInput(Date.now())
        window.scrollTo(0, 0)
      })
    } else {
      setError(error)
      setLoading(false)
    }
  }

  const handleRemoveImage = (e, idImage) => {
    e.preventDefault()
    let newListImages = listImages.filter(image => image.id !== idImage)
    setListImages(newListImages)
  }

  const handleCreateProductVariation = (e) => {
    e.preventDefault()
    setLoading(true)

    let error = checkFormError()
    console.info(currentProduct)
    if (!error) {
      console.info("NO ERROR")
      let newProduct = {...currentProduct, singleGem: singleGem, id: uuid(), images: listImages.map(image => ({id: image.id}))}
      addVariation(newProduct)
        .then(prevProduct => {
          createProductVariation(newProduct).then(() => {
            setLoading(false)
            toaster.success(
              "Votre produit à bien été ajouté"
            )
            setProduct(initialProduct)
            setClearInput(Date.now())
            window.scrollTo(0, 0)
          }).catch(err => console.error("ERROR NEW VARIATION", err))
        })
    } else {
      createProductVariation().then(() => {
        setLoading(false)
        toaster.success(
          "Votre produit à bien été ajouté"
        )
        setProduct(initialProduct)
        setClearInput(Date.now())
        window.scrollTo(0, 0)
      }).catch(err => console.error("ERROR NEW VARIATION", err))
    }
  }

  const uploadImage = async(source) => {
    if (source.length > 0) {
      setImageLoading(true)
      const fileName = uuid()
      const file = source[0]
      const urlImage = URL.createObjectURL(file)
      Storage.put(`${fileName}.png`, file, {
        contentType: "image/png"
      })
        .then(() => {
          setListImages([
            ...listImages,
            {
              id: fileName,
              preview: urlImage
            }
          ])
        })
        .then(() => setImageLoading(false))
        .catch(err => console.error("S3 Error: ", err))
    }
  }

  return (
    <div>
      <Pane
        background="white"
        width={"530px"}
        padding={24}
        marginBottom={24}
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
      >
        <Pane display="flex" justifyContent="flex-start">
          {isVariation
            ? <Heading fontFamily={"Lato"} fontSize={"17px"} fontWeight={400} is="h3">Création d'une nouvelle
              variation</Heading>
            : <Heading fontFamily={"Lato"} fontSize={"17px"} fontWeight={400} is="h3">Création d'un nouveau
              bijou</Heading>
          }
        </Pane>
        <Separation/>
        <Pane key={clearInput}>
          {/*ROW 1*/}
          {!isVariation && <Pane
            display="flex"
            justifyContent="space-between"
            marginBottom={"15px"}
          >
            {/*PRODUCT TYPE*/}
            <Pane>
              <Label>Type de produit</Label>
              <Autocomplete
                title="Liste des types"
                onChange={e => handleChange("attributeType", e)}
                items={attributeTypeList()}
              >
                {(props) => {
                  const { getInputProps, getRef, inputValue, openMenu } = props
                  return (
                    <TextInput
                      width={230}
                      isInvalid={!!isError.attributeType}
                      placeholder="Sélectionner..."
                      value={currentProduct.attributeType}
                      innerRef={getRef}
                      {...getInputProps({
                        onFocus: () => {
                          openMenu()
                        }
                      })}
                    />
                  )
                }}
              </Autocomplete>
            </Pane>
            {/*PRODUCT COLLECTION*/}
            <Pane>
              <Label>Collection</Label>
              <Autocomplete
                title="Liste des collections"
                onChange={e => handleChange("collection", e)}
                items={listCollections.map(item => item.data.name)}
              >
                {(props) => {
                  const { getInputProps, getRef, inputValue, openMenu } = props
                  return (
                    <TextInput
                      width={230}
                      isInvalid={!!isError.collection}
                      placeholder="Sélectionner..."
                      value={currentProduct.collection}
                      innerRef={getRef}
                      {...getInputProps({
                        onFocus: () => {
                          openMenu()
                        }
                      })}
                    />
                  )
                }}
              </Autocomplete>
            </Pane>
          </Pane>}
          {/*ROW 2*/}
          <Pane
            display="flex"
            justifyContent="space-between"
            marginBottom={"15px"}
          >
            {/*PRODUCT METAL*/}
            <Pane>
              <Label>Métal</Label>
              <Autocomplete
                title="Type de métal"
                onChange={e => handleChange("attributeMetal", e)}
                items={attributeMetalList()}
              >
                {(props) => {
                  const { getInputProps, getRef, inputValue, openMenu } = props
                  return (
                    <TextInput
                      width={230}
                      isInvalid={!!isError.attributeMetal}
                      placeholder="Sélectionner..."
                      value={currentProduct.attributeMetal}
                      innerRef={getRef}
                      {...getInputProps({
                        onFocus: () => {
                          openMenu()
                        }
                      })}
                    />
                  )
                }}
              </Autocomplete>
            </Pane>
            {/*PRODUCT GEM*/}
            <Pane>
              {!isVariation && <div>
                <Label>Pierre</Label>
                <Autocomplete
                  title="Type de pierre"
                  onChange={e => handleChange("attributeGem", e)}
                  items={attributeGemList()}
                >
                  {(props) => {
                    const { getInputProps, getRef, inputValue, openMenu } = props
                    return (
                      <TextInput
                        width={230}
                        isInvalid={!!isError.attributeGem}
                        placeholder="Sélectionner..."
                        value={currentProduct.attributeMetal}
                        innerRef={getRef}
                        {...getInputProps({
                          onFocus: () => {
                            openMenu()
                          }
                        })}
                      />
                    )
                  }}
                </Autocomplete>
              </div>}
              {isVariation && !singleGem && <div>
                <Label>Pierre</Label>
                <Autocomplete
                  title="Type de pierre"
                  onChange={e => handleChange("attributeGem", e)}
                  items={attributeGemList()}
                >
                  {(props) => {
                    const { getInputProps, getRef, inputValue, openMenu } = props
                    return (
                      <TextInput
                        width={230}
                        isInvalid={!!isError.attributeGem}
                        placeholder="Sélectionner..."
                        value={currentProduct.attributeMetal}
                        innerRef={getRef}
                        {...getInputProps({
                          onFocus: () => {
                            openMenu()
                          }
                        })}
                      />
                    )
                  }}
                </Autocomplete>
              </div>}
            </Pane>
          </Pane>
          {/*PRODUCT GEM WEIGHT*/}
          {currentProduct.attributeGem === "Diamants" && !isVariation &&
          <Pane display={"flex"} justifyContent={"space-between"} marginBottom={"15px"}>
            <Pane>
              <Label>Poids du Diamant</Label>
              <Checkbox
                label="Autoriser le choix du poids"
                checked={singleGem}
                onChange={e => setSingleGem(e.target.checked)}
              />
            </Pane>
          </Pane>
          }

          {/*DESCRIPTION*/}
          <Label>Description du produit</Label>
          <Textarea
            id="description-area"
            value={currentProduct.description}
            onChange={(e) => handleChange("description", e.target.value)}
            name="description"
            isInvalid={!!isError.description}
            marginBottom={"10px"}
          />

          {/*GENRE / PLURAL / START FROM*/}
          <Pane
            display="flex"
            justifyContent="space-between"
            marginBottom={"15px"}
          >
            <Pane>
              <Label>Genre</Label>
              <Autocomplete
                title="Genre"
                onChange={e => handleChange("genre", e)}
                items={attributeGenre()}
              >
                {(props) => {
                  const { getInputProps, getRef, inputValue, openMenu } = props
                  return (
                    <TextInput
                      width={230}
                      isInvalid={!!isError.genre}
                      placeholder="Sélectionner..."
                      value={currentProduct.genre}
                      innerRef={getRef}
                      {...getInputProps({
                        onFocus: () => {
                          openMenu()
                        }
                      })}
                    />
                  )
                }}
              </Autocomplete>
            </Pane>
            <Pane>
              <Label>Référence</Label>
              <TextInput
                width={230}
                onChange={e => handleChange("ref", e.target.value)}
                value={currentProduct.ref || ""}
              />
            </Pane>

          </Pane>

          {/*PRICE & REF*/}
          <Pane
            display="flex"
            justifyContent="space-between"
            marginBottom={"15px"}
          >
            {/*REF*/}
            <Pane>

              <Label>Prix (0 = prix sur demande)</Label>
              <TextInput
                width={230}
                onChange={e => handleChange("price", e.target.value)}
                value={currentProduct.price || ""}
              />
            </Pane>
            {/*Price*/}
              {currentProduct.price > 0 && (
                <WrapperInput >
                  <Label>Afficher le prix "à partir de "</Label>
                  <Checkbox
                    checked={currentProduct.priceStartFrom}
                    onChange={e => handleChange("priceStartFrom", e.target.checked)}
                  />
                </WrapperInput>
              )}

          </Pane>

          {/*IMAGE PREVIEW*/}
          <Pane display="flex" justifyContent="space-between" marginBottom={"30px"}>
            <Pane>
              <Label>Image du produit</Label>
              <FilePicker
                width={250}
                marginBottom={32}
                onChange={file => uploadImage(file)}
                placeholder="Choisir une image"
              />
            </Pane>
          </Pane>
          <WrapperListPreviewImage>
            {listImages.length > 0 && listImages.map(image => (
              <WrapperPreviewImage key={image.id}>
                <PreviewImage src={image.preview}/>
                <ButtonRemoveImage onClick={(e) => handleRemoveImage(e, image.id)}>
                  <MdRemoveCircle size={16}/>
                </ButtonRemoveImage>
              </WrapperPreviewImage>
            ))}
            {imageLoading && <Spinner size={32}/>}
          </WrapperListPreviewImage>


          <Pane display="flex" justifyContent="flex-end">
            <Button
              appearance="minimal" height={40} intent="warning"
              marginRight={12} iconBefore="plus"
              onClick={handleAddVariation}
              disabled={listImages.length < 1 || imageLoading}
            >
              Ajouter une variation
            </Button>
            {isVariation ? (
              <Button
                isLoading={!!loading}
                height={40} marginRight={12} iconBefore="tick"
                onClick={handleCreateProductVariation}
              >
                Terminer
              </Button>
            ) : (
              <Button
                isLoading={!!loading}
                height={40} marginRight={12} iconBefore="tick"
                onClick={handleCreateProduct}
                disabled={listImages.length < 1 || imageLoading}
              >
                Valider le produit
              </Button>
            )}

          </Pane>
        </Pane>
      </Pane>
    </div>
  )
}
