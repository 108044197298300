import React, { useEffect, useState } from "react"

import useAdmin from "../../../hooks/useAdmin"
import {
  Wrapper,
  Container,
  HeadingTitle,
  HeadingWrapper,
  Separator, FormSelect, InputWrapper, WrapperMenu,
  WrapperTitle, WrapperListMenu, HeadingCat, WrapperCollection
} from "./StyledAdminMegaMenu"

import {  AiOutlineCheck } from "react-icons/ai"
import { uuid } from "uuidv4"
import { Label } from "../../Account/Profile/StyledAccountProfile"
import { ButtonSubmit } from "../Collections/StyledAdminCollections"

export default ({setSuccess}) => {
  const { getMegaMenu, updateMegaMenu, getCollections } = useAdmin()
  const [listMegaMenu, setListMegaMenu] = useState([])
  const [currentMenu, setCurrentMenu] = useState({})
  const [allCollections, setAllCollections] = useState([])

  useEffect(() => {
    if (listMegaMenu.length < 1) {
      getMegaMenu().then((res) => setListMegaMenu(res))
    }
    if (allCollections.length < 1) {
      getCollections().then((res) => setAllCollections(res))
    }
  }, [])


  const handleChange = (e, menuParent, collectionId) => {
    e.preventDefault()
    setListMegaMenu(listMegaMenu.map((menu) => {
      if (menu.data.parent === menuParent) {
        menu.data.collections[collectionId] = e.target.value
      }
      return menu
    }))
  }

  const handleSaveMenu = (e) => {
    e.preventDefault()
    updateMegaMenu(listMegaMenu).then((res) => {
      setSuccess("Vos modifications ont été sauvegardées")
    })
  }

  return (
    <Wrapper>
      <Container>
        <HeadingWrapper>
          <WrapperTitle>
            <HeadingTitle>Choix des collections</HeadingTitle>
          </WrapperTitle>
          <Separator/>
        </HeadingWrapper>
        <WrapperListMenu>
          {listMegaMenu.length > 0 && listMegaMenu.map((menu, idMegaMenu) => (
            <WrapperMenu key={menu.id + menu.type}>
              <HeadingCat>{menu.data.parent}</HeadingCat>
              <WrapperCollection>
                {menu.data.collections.length > 0 && menu.data.collections.map((collection, idCollection) => (
                  <InputWrapper key={menu.id + menu.data.parent + idCollection} style={{marginBottom: "15px"}}>
                    <Label>Collection {idCollection + 1}</Label>
                    <FormSelect
                      value={listMegaMenu[idMegaMenu].data.collections[idCollection]}
                      onChange={(e) => handleChange(e, menu.data.parent, idCollection)}>
                      {allCollections.map((item, index) => (
                        <option key={item.id} value={item.id}>{item.data.name}</option>
                      ))}
                    </FormSelect>
                  </InputWrapper>
                ))}
              </WrapperCollection>
            </WrapperMenu>
          ))}
        </WrapperListMenu>
        <ButtonSubmit className={"visible"} onClick={handleSaveMenu}>
          <AiOutlineCheck/>
          <span style={{marginLeft: "1rem"}}>Sauvegarder</span>
        </ButtonSubmit>
      </Container>
    </Wrapper>
  )
}
