import styled from "@emotion/styled"
import { colors } from "../../styles"

const FormContainer = styled.div`

  `

const Label = styled.p`
  font-family: Lato;
  font-size: 0.9rem;
  margin-bottom: 8px;
  color: #66778a;
  margin-right: 20px;
`

const StyledInput = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  `

const StyledButton = styled.div` 
    width: 100%;
    display: flex;
    justify-content: center;
  `

const PreviewImage = styled.img`
   max-width: 125px;
`

const PreviewImageVariation = styled.img`
   max-height: 70px;
`

const TitleForm = styled.div`
  font-weight: 500;
  color: #66778a;
`

const WrapperListPreviewImage = styled.div` 
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  `

const WrapperPreviewImage = styled.div` 
   margin-right: 1rem;
   margin-bottom: 1rem;
   position: relative;
  `

const ButtonRemoveImage = styled.button` 
  display: flex;
  z-index: 10;
  position: absolute;
  top: -10px;
  right: -10px;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  color: ${colors.warning};
  font-size: 10px;
  background: #fafafa;
  padding: .2rem;
  line-height: 0;
  font-size: 13px;
  font-weight: 100;
  border-radius: 100%;
  cursor: pointer;
  transition-duration: 0.3s;
  &:hover {
    background: ${colors.warning};
    color: white;
  }
  `

const WrapperInput = styled.div` 
   display: flex;
   padding-top: 2rem;
   width: 100%;
   align-items: center;
   justify-content: center;
  `

export {
  TitleForm,
  Label,
  FormContainer,
  StyledInput,
  StyledButton,
  WrapperInput,
  PreviewImage,
  PreviewImageVariation,
  WrapperListPreviewImage,
  WrapperPreviewImage,
  ButtonRemoveImage
}
