import React, { useEffect, useState } from "react"

import useAdmin from "../../../hooks/useAdmin"
import {
  Wrapper,
  Container,
  HeadingTitle,
  HeadingWrapper,
  Separator,
  FormInput,
  ImagePreview,
  ButtonSubmit,
  ButtonNewSlider,
  WrapperTitle,
  WrapperListInput,
  SlideImagePreview,
  InputWrapper,
  WrapperListCollections,
  CollectionWrapper,
  CollectionImageWrapper, CollectionImage, CollectionTitle
} from "./StyledAdminCollections"
import { ButtonRemoveImage } from "../CreateProductForm/styles"
import { MdRemoveCircle, MdAdd } from "react-icons/md"
import {  AiFillPlusCircle, AiOutlineCheck } from "react-icons/ai"
import { uuid } from "uuidv4"
import { FilePicker, Spinner } from "evergreen-ui"
import { Storage } from "aws-amplify"
import { Label } from "../../Account/Profile/StyledAccountProfile"

export default () => {
  const { getCollections, updateSliderList, updateCollection, deleteCollection } = useAdmin()
  const [listCollections, setListCollections] = useState([])
  const [currentCollection, setCurrentCollection] = useState({})
  const [isError, setError] = useState({})
  const [imageCollectionPreview, setCollectionPreview] = useState(false)
  const [imageLoading, setImageLoading] = useState(false)

  useEffect(() => {
    if (!listCollections.length > 0) {
      getCollections().then((res) => {
        if (res) {
          console.info("COLLECTION FECTH", res)
          setListCollections(res)
        }
      })
    }
  }, [])

  const handleSelectCollection = (e, collectionId) => {
    e.preventDefault()
    setCurrentCollection(listCollections.find(item => item.id === collectionId ))
  }

  const handleRemoveCollection = (e, collectionId) => {
    e.preventDefault()
    let newCollectionList = listCollections.filter(image => image.id !== collectionId)
    deleteCollection(collectionId).then(() => {
      setListCollections(newCollectionList)
    })
  }

  const handleCreateCollection = (e) => {
    e.preventDefault()
    setCurrentCollection({
      id: uuid(),
      type: "collection",
      data: {
        name: "",
        description: "",
        imageId: "",
      }
    })
  }

  const handleChange = (e, name) => {
    e.preventDefault()
    setCurrentCollection({
      ...currentCollection,
      data: {
        ...currentCollection.data,
        [name]: e.target.value
      }
    })
    if (isError[name]) setError({...isError, [name]: false})
  }

  const uploadImage = async(source) => {
    if (source.length > 0) {
      setImageLoading(true)
      const fileName = uuid()
      const file = source[0]
      const urlImage = URL.createObjectURL(file)
      Storage.put(`${fileName}.png`, file, {
        contentType: "image/png"
      })
        .then(() => {
          setCollectionPreview(urlImage)
          setCurrentCollection({
            ...currentCollection,
            data: {
              ...currentCollection.data,
              imageId: fileName
            }
          })
        } )
        .then(() => setImageLoading(false))
        .catch(err => console.error("S3 Error: ", err))
    }
  }

  const handleSaveCollection = (e) => {
    e.preventDefault()
    let newList = listCollections.filter(slide => slide.id !== currentCollection.id )
    newList = [...newList, currentCollection]
    updateCollection(currentCollection).then((res) => {
      setListCollections(newList)
      setCurrentCollection(false)
    })
  }

  return (
    <Wrapper>
      <Container>
        <HeadingWrapper>
          <WrapperTitle>
            <HeadingTitle>Liste des collections</HeadingTitle>
            <ButtonNewSlider onClick={handleCreateCollection}>
              <AiFillPlusCircle/>
              <span style={{marginLeft: ".3rem"}}>Ajouter une collection</span>
            </ButtonNewSlider>
          </WrapperTitle>
          <Separator/>
        </HeadingWrapper>
        <WrapperListCollections>
          {listCollections.length > 0 && listCollections.map(collection => (
            <CollectionWrapper key={collection.id}>
              <CollectionImageWrapper onClick={(e) => handleSelectCollection(e, collection.id)}>
                <CollectionImage src={process.env.IMAGE_ENDPOINT + collection.data.imageId + "_SMALL.png"} alt={collection.data.name} title={collection.data.name}/>
              </CollectionImageWrapper>
              <CollectionTitle>
                {collection.data.name}
              </CollectionTitle>
              <ButtonRemoveImage onClick={(e) => handleRemoveCollection(e, collection.id)}>
                <MdRemoveCircle size={16}/>
              </ButtonRemoveImage>
            </CollectionWrapper>
          ))}
        </WrapperListCollections>
      </Container>

      {currentCollection.id && (
        <Container>
          <HeadingWrapper>
            <HeadingTitle>Edition de la collection</HeadingTitle>
            <Separator/>
          </HeadingWrapper>
          <WrapperListInput>
            <SlideImagePreview>
              {imageCollectionPreview && (<ImagePreview src={imageCollectionPreview}/>)}
              {!imageCollectionPreview && currentCollection.data.imageId && (<ImagePreview src={process.env.IMAGE_ENDPOINT + currentCollection.data.imageId + "_SMALL.png"}/>)}
            </SlideImagePreview>
            {imageLoading && <Spinner marginX={"auto"} size={32}/>}
            <InputWrapper>
              <FilePicker
                width={250}
                onChange={file => uploadImage(file)}
                placeholder="Choisir une image"
              />
            </InputWrapper>
            <InputWrapper>
              <Label>Nom</Label>
              <FormInput
                type={"name"}
                className={!!isError.title && "input-error"}
                value={currentCollection.data.name}
                onChange={e => handleChange(e, "name")}
              />
            </InputWrapper>
            <InputWrapper>
              <Label>Description</Label>
              <FormInput
                type={"description"}
                className={!!isError.subTitle && "input-error"}
                value={currentCollection.data.description}
                onChange={e => handleChange(e, "description")}
              />
            </InputWrapper>
          </WrapperListInput>
          <ButtonSubmit className={"visible"} onClick={handleSaveCollection}>
            <AiOutlineCheck/>
            <span style={{marginLeft: "1rem"}}>Sauvegarder</span>
          </ButtonSubmit>
        </Container>
      )}
    </Wrapper>
  )
}
