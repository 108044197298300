import React, { useState } from "react"
import { Button, Heading, Pane, PersonIcon, PropertiesIcon, ShopIcon } from "evergreen-ui"
import styled from "@emotion/styled"

import { AiTwotoneFileWord } from "react-icons/ai"
import { FiFileText, FiImage } from "react-icons/fi"
import { BsFillCollectionFill } from "react-icons/bs"
import { MdCollections, MdMenu, MdHome } from "react-icons/md"
import { GiHamburgerMenu } from "react-icons/gi"
import { FaUsers } from "react-icons/fa"
import { RiVipDiamondFill } from "react-icons/ri"

import { EditIcon, IconButton, PlusIcon } from "evergreen-ui"
import { colors } from "../../styles"

const NavContainer = styled.div`
  padding: 15px;
  height: 450px;
  min-width: 215px;
  padding-top: 10px;
  align-items: left;
  flex-direction: column;
  display: flex;
  background: white;
  border-radius: 5px;
  transition-duration: 1s;
/*  &:hover {
    width: 200px;
    transition-duration: 1s;
  }*/
`

const NavItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition-durations: 0.3s;
  padding: 12px 12px;
  &:hover {
    background: #fbfbfb;
  };
  border-radius: 5px;
`

const NavTitle = styled.span`
  color: white;
  margin-left: 20px;
  font-size: 0.9rem;
  font-family: Lato;
  color: ${colors.darkGrey}
`

const Separator = styled.div`
  width: 100%;
  margin: auto;
  height: 1px;
  background: #f5f5f5;
  margin-top: 5px;
  margin-bottom: 5px;
`



export default({setNavigation}) => {
  return (
    <NavContainer >
      <NavItem onClick={() => setNavigation("createProduct")}>
        <PlusIcon color={"#6a6d6f"} size={20}/>
        <NavTitle>Ajouter un bijou</NavTitle>
      </NavItem>
      <Separator/>
      <NavItem onClick={() => setNavigation("editProduct")}>
        <RiVipDiamondFill color={"#6a6d6f"} size={20}/>
        <NavTitle>Modifier un bijou</NavTitle>
      </NavItem>
      <Separator/>
      <NavItem>
        <FaUsers color={"#6a6d6f"} size={18} />
        <NavTitle>Clients</NavTitle>
      </NavItem>
      <Separator/>
      {/*<NavItem>
        <FiFileText color={"#6a6d6f"} size={20} />
        <NavTitle >Commandes</NavTitle>
      </NavItem>*/}
      <NavItem onClick={() => setNavigation("editSlider")}>
        <MdCollections color={"#6a6d6f"} size={20}/>
        <NavTitle>Slider</NavTitle>
      </NavItem>
      <Separator/>
      <NavItem onClick={() => setNavigation("editCollections")}>
        <BsFillCollectionFill color={"#6a6d6f"} size={20}/>
        <NavTitle>Collections</NavTitle>
      </NavItem>
      <NavItem onClick={() => setNavigation("editMenu")}>
        <GiHamburgerMenu color={"#6a6d6f"} size={20}/>
        <NavTitle>Menu</NavTitle>
      </NavItem>
      <NavItem onClick={() => setNavigation("editHome")}>
        <MdHome color={"#6a6d6f"} size={20}/>
        <NavTitle>Page d'accueil</NavTitle>
      </NavItem>
    </NavContainer>
  )
}
