import { useState } from "react"

export default (initialProduct) => {
  const [currentProduct, setProduct] = useState(initialProduct)
  const [isError, setError] = useState([])
  return {
    currentProduct,
    setProduct,
    isError,
    setError,
  }
}
