import React, { useEffect, useState } from "react"

import CreateProductForm from "../CreateProductForm"
import CreateProductDisplay from "../CreateProductDisplay"
import useAdmin from "../../../hooks/useAdmin"

import styled from "@emotion/styled"
import { createProduct, creaProductVariation, createProductVariation } from "../../../utils/productHelpers"

const Container = styled.div`
  display: flex;
  flex: 1;
  padding-left: 30px;
`

export default () => {
  const { listProducts, clearListProducts, deleteVariation, getCollections } = useAdmin()
  const [listCollections, setListCollections] = useState([])
  const [isVariation, setIsVariation] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (listCollections.length < 1) {
      getCollections().then((res) => setListCollections(res))
    }
  }, [])

  useEffect(() => {
    if (listProducts.length > 0) setIsVariation(true)
    else setIsVariation(false)
  }, [listProducts])

  const handleCreateProduct = (currentProduct) => {
    return new Promise(resolve => {
      createProduct(currentProduct, listCollections)
        .then(() => {
          clearListProducts()
          resolve()
          console.info("Admin: SUCCESS ")
        })
        .catch(() => {
          clearListProducts()
        })
    })
  }

  const handleCreateProductVariation = (product) => {
    return new Promise(resolve => {
      console.info("LIST PRODUCTS", listProducts)
      createProductVariation([...listProducts, product], listCollections)
        .then(() => {
          clearListProducts()
          resolve()
          console.info("CreateProductIndex: SUCCESS ")
        })
        .catch(() => {
          clearListProducts()
        })
    })
  }

  const handlerDeleteItem = (e, product) => {
    e.preventDefault()
    deleteVariation(product,listProducts)
  }

  console.info("RENDER: ADMIN TEMPLATE")

  return (
    <Container>
      <CreateProductForm listCollections={listCollections} isSuccess={success} isVariation={isVariation} createProduct={handleCreateProduct} createProductVariation={handleCreateProductVariation}/>
      <CreateProductDisplay deleteItem={handlerDeleteItem} listProducts={listProducts} />
    </Container>
  )
}
