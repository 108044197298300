import React, { useEffect, useState, useMemo } from "react"

import useAdmin from "../../../hooks/useAdmin"
import {
  Wrapper,
  Container,
  HeadingTitle,
  HeadingWrapper,
  Separator, FormInput,
  WrapperListSlides, ImagePreview, ButtonSubmit,
  SlideWrapper, ButtonNewSlider, WrapperTitle, WrapperListInput, SlideImagePreview, InputWrapper, WrapperImageInput
} from "./StyledAdminSlider"
import { ButtonRemoveImage } from "../CreateProductForm/styles"
import { MdRemoveCircle, MdAdd } from "react-icons/md"
import {  AiFillPlusCircle, AiOutlineCheck } from "react-icons/ai"
import { uuid } from "uuidv4"
import { FilePicker, Spinner } from "evergreen-ui"
import { Storage } from "aws-amplify"
import { Label } from "../../Account/Profile/StyledAccountProfile"

export default ({setSuccess}) => {
  const { getSlides, updateSliderList } = useAdmin()
  const [listSlides, setListSlides] = useState(false)
  const [currentSlide, setCurrentSlide] = useState({})
  const [isError, setError] = useState({})
  const [imageSlidePreview, setSlidePreview] = useState(false)
  const [imageLoading, setImageLoading] = useState(false)

  useEffect(() => {
    if (!listSlides) {
      getSlides().then((res) => {
        setListSlides({...res, data: JSON.parse(res.data)})
      })
    }
  }, [listSlides])

  const handleSelectSlide = (e, slideId) => {
    e.preventDefault()
    setCurrentSlide(listSlides.data.find(item => item.id === slideId ))
  }

  const handleRemoveSlide = (e, idImage) => {
    e.preventDefault()
    let newListSlider = listSlides.data.filter(image => image.id !== idImage)
    updateSliderList(listSlides, newListSlider).then((res) => {
      setListSlides({...res, data: JSON.parse(res.data)})
    })
  }

  const handleCreateSlide = (e) => {
    e.preventDefault()
    setCurrentSlide({
      id: uuid(),
      titlePos: "left",
      titleColor: "#000000",
      title: "",
      subTitle: "",
      ctaTitle: "",
      ctaLink: "",
      imageMobile: "",
      imageDesktop: "",
    })
  }

  const handleChange = (e, name) => {
    e.preventDefault()
    console.log(e.target.value)
    setCurrentSlide({
      ...currentSlide,
      [name]: e.target.value
    })
    if (isError[name]) setError({...isError, [name]: false})
  }

  const uploadImage = async(source, view) => {
    if (source.length > 0) {
      setImageLoading(true)
      const fileName = `slider-${uuid()}`
      const file = source[0]
      const urlImage = URL.createObjectURL(file)
      Storage.put(`${fileName}.png`, file, {
        contentType: "image/png"
      })
        .then(() => {
          setSlidePreview({
            ...imageSlidePreview,
            [view]: urlImage
          })
          setCurrentSlide({
            ...currentSlide,
            [view]: fileName
          })
        } )
        .then(() => setImageLoading(false))
        .catch(err => console.error("S3 Error: ", err))
    }
  }

  const handleSaveSlider = (e) => {
    e.preventDefault()
    let newList = listSlides.data.filter(slide => slide.id !== currentSlide.id )
    newList = [...newList, currentSlide]
    updateSliderList(listSlides, newList).then((res) => {
      setListSlides({...res, data: JSON.parse(res.data)})
      setCurrentSlide(false)
      setSuccess("Vos modifications ont été sauvegardées")
    })
  }


  const renderListSlide = useMemo(() => (
    <Container>
      <HeadingWrapper>
        <WrapperTitle>
          <HeadingTitle>Liste des slides</HeadingTitle>
          <ButtonNewSlider onClick={handleCreateSlide}>
            <AiFillPlusCircle/>
            <span style={{marginLeft: ".3rem"}}>Ajouter un slide</span>
          </ButtonNewSlider>
        </WrapperTitle>
        <Separator/>
      </HeadingWrapper>
      <WrapperListSlides>
        {listSlides.id && listSlides.data.length > 0 && listSlides.data.map(slide => (
          <SlideWrapper key={slide.id}>
            <div onClick={(e) => handleSelectSlide(e, slide.id)}>
              <img src={process.env.IMAGE_ENDPOINT + slide.imageDesktop + "_SMALL.png"} alt={slide.title} title={slide.title}/>
            </div>
            <ButtonRemoveImage onClick={(e) => handleRemoveSlide(e, slide.id)}>
              <MdRemoveCircle size={16}/>
            </ButtonRemoveImage>
          </SlideWrapper>
        ))}
      </WrapperListSlides>
    </Container>
  ), [listSlides])

  return (
    <Wrapper>
      {renderListSlide}
      {currentSlide.id && (
        <Container>
          <HeadingWrapper>
            <HeadingTitle>Edition du slide</HeadingTitle>
            <Separator/>
          </HeadingWrapper>
          <WrapperListInput>
            <WrapperImageInput>
              <InputWrapper>
                <Label>Image Desktop</Label>
                <FilePicker
                  width={200}
                  onChange={file => uploadImage(file, "imageDesktop")}
                  placeholder="Choisir une image"
                />
              </InputWrapper>
              <SlideImagePreview>
                {imageSlidePreview.imageDesktop && (<ImagePreview src={imageSlidePreview.imageDesktop}/>)}

                {!imageSlidePreview.imageDesktop && currentSlide.imageDesktop && (<ImagePreview src={process.env.IMAGE_ENDPOINT + currentSlide.imageDesktop + "_SMALL.png"}/>)}
              </SlideImagePreview>
            </WrapperImageInput>
            <WrapperImageInput>
              <InputWrapper>
                <Label>Image Mobile</Label>
                <FilePicker
                  width={200}
                  onChange={file => uploadImage(file, "imageMobile")}
                  placeholder="Choisir une image"
                />
              </InputWrapper>
              <SlideImagePreview>
                {imageSlidePreview.imageMobile && (<ImagePreview src={imageSlidePreview.imageMobile}/>)}

                {!imageSlidePreview.imageMobile && currentSlide.imageMobile && (<ImagePreview src={process.env.IMAGE_ENDPOINT + currentSlide.imageMobile + "_SMALL.png"}/>)}
              </SlideImagePreview>
            </WrapperImageInput>
            <InputWrapper>
              <Label>Couleur Titrage</Label>
              <FormInput
                type={"color"}
                value={currentSlide.titleColor}
                onChange={e => handleChange(e, "titleColor")}
              />
            </InputWrapper>
            <InputWrapper>
              <Label>Titre</Label>
              <FormInput
                type={"text"}
                className={!!isError.title && "input-error"}
                value={currentSlide.title}
                onChange={e => handleChange(e, "title")}
              />
            </InputWrapper>
            <InputWrapper>
              <Label>Description</Label>
              <FormInput
                type={"text"}
                className={!!isError.subTitle && "input-error"}
                value={currentSlide.subTitle}
                onChange={e => handleChange(e, "subTitle")}
              />
            </InputWrapper>
            <InputWrapper>
              <Label>Titre CTA</Label>
              <FormInput
                type={"text"}
                className={!!isError.ctaTitle && "input-error"}
                value={currentSlide.ctaTitle}
                onChange={e => handleChange(e, "ctaTitle")}
              />
            </InputWrapper>
            <InputWrapper>
              <Label>Lien CTA</Label>
              <FormInput
                type={"text"}
                className={!!isError.ctaLink && "input-error"}
                value={currentSlide.ctaLink}
                onChange={e => handleChange(e, "ctaLink")}
              />
            </InputWrapper>
          </WrapperListInput>
          <ButtonSubmit className={"visible"} onClick={handleSaveSlider}>
            <AiOutlineCheck/>
            <span style={{marginLeft: "1rem"}}>Sauvegarder</span>
          </ButtonSubmit>
        </Container>
      )}
    </Wrapper>
  )
}
