import React, { useEffect, useState } from "react"
import EditProductForm from "../EditProductForm"
import useAdmin from "../../../hooks/useAdmin"

import { editProduct, onSearch, onSearch2, removeProduct } from "../../../utils/productHelpers"

import {
  MainWrapper,
  ProductThumbnail,
  SearchResultWrapper,
  SearchWrapper, TitleProductCollection,
  TitleProductGemMetal, TitleProductType,
  ImageFeatured, ImageThumb, DeleteButton
} from "./StyledEditProduct"
import { Dialog, SearchInput, toaster } from "evergreen-ui"
import { AiOutlineDelete } from "react-icons/ai"

export default () => {
  const { getAllProduct, deleteProduct, getCollections } = useAdmin()
  const [productSearch, setProductSearch] = useState([])
  const [listCollections, setListCollections] = useState([])
  const [productList, setProductList] = useState([])
  const [productSelected, setProductSelected] = useState(false)
  const [query, setQuery] = useState("")
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  useEffect(() => {
    if (listCollections.length < 1) {
      getCollections().then((res) => setListCollections(res))
    }
    if (productList.length < 1) {
      getAllProduct().then((res) => setProductList(res))
    }
  }, [])

  const toggleSuccess = (message) => {
    window.scrollTo(0, 0)
    if (productSelected) setProductSelected(false)
    toaster.success(
      message
    )
  }

  const handleEditProduct = (currentProduct) => {
    editProduct(productList, currentProduct, listCollections)
      .then(() => {
        toggleSuccess("Votre produit à bien été modifié")
        setProductList(productList.map(item => {
         if (item.id === currentProduct.id) item = currentProduct
         return item
        }))
      })
      .catch((err) => {
        console.error(err)
        setProductSelected(false)
      })
  }

  const handleSearch = (e) => {
    e.preventDefault()
    setQuery(e.target.value)
    if (query.length > 1) {
      const res = onSearch2(query, productList, ["attributeType", "attributeGem", "attributeMetal", "ref", "collection"], productList)
      setProductSearch(res || [])
    } else {
      setProductSearch([])
    }
  }

  const handleRedirection = () => {
    setProductSelected(false)
  }

  const handleSelected = (e, item) => {
    e.preventDefault()
    setProductSelected(item)
  }

  const toggleConfirmDelete = (e, item) => {
    e.preventDefault()
    setShowConfirmDelete(item)
  }

  const clearProduct = (product) => {
    let newListProducts = productList.filter(item => item.id !== product.id)
    let newSearchProducts = productSearch.filter(item => item.id !== product.id)
    setProductList(newListProducts)
    setProductSearch(newSearchProducts)
  }

  const handlerDeleteProduct = () => {
    let productToDelete = showConfirmDelete
    if (productToDelete.variations.length > 0) {
      removeProduct(productList, productToDelete, listCollections)
        .then(() => {
          deleteProduct(productToDelete)
            .then(() => {
              clearProduct(productToDelete)
              setShowConfirmDelete(false)
              toggleSuccess("Votre produit à bien été supprimé")
              console.error("Admin: SUCCESS REMOVE PRODUCT ")
            })
        })
        .catch((err) => {
          console.error("Admin: FAIL REMOVE PRODUCT VARIATION ", err)
        })
    } else {
      deleteProduct(productToDelete)
        .then(() => {
          clearProduct(productToDelete)
          setShowConfirmDelete(false)
          toggleSuccess("Votre produit à bien été supprimé")
          console.error("Admin: SUCCESS REMOVE PRODUCT ")
        })
        .catch((err) => {
          console.error("Admin: FAIL REMOVE PRODUCT ", err)
        })
    }
  }

  const renderItem = (listItems) => listItems.map((item, id) =>
    <ProductThumbnail key={id}>
      <DeleteButton onClick={(e) => toggleConfirmDelete(e, item)}>
        <AiOutlineDelete size={16}/>
      </DeleteButton>
      <ImageThumb onClick={(e) => handleSelected(e, item)}>
        <ImageFeatured alt={"resultat de recherche"} src={process.env.IMAGE_ENDPOINT + item.images[0].id + "_SMALL.png"}/>
      </ImageThumb>
      <TitleProductCollection>
        {item.collection}
      </TitleProductCollection>
      <TitleProductType>
        {item.attributeType}
      </TitleProductType>
      <TitleProductGemMetal>
        {item.attributeGem} {item.attributeMetal}
      </TitleProductGemMetal>
    </ProductThumbnail>
  )

  return (
    <MainWrapper>
      <Dialog
        topOffset={"20vmin"}
        isShown={!!showConfirmDelete}
        title="Veuillez confirmer la suppression"
        intent="danger"
        onCloseComplete={() => setShowConfirmDelete(false)}
        onConfirm={handlerDeleteProduct}
        confirmLabel="Supprimer le bijoux"
      >
        <div style={{ fontFamily: "Lato" }}>
          Vous êtes sur le point de supprimer le bijoux: <br/> <p
          style={{ fontWeight: "bold" }}> {showConfirmDelete.attributeType} {showConfirmDelete.attributeGem} {showConfirmDelete.attributeMetal}
          <br/>Collection {showConfirmDelete.collection} </p>
        </div>

      </Dialog>
      {!productSelected ? (
        <>
          <SearchWrapper>
            Rechercher un produit :
            <SearchInput onChange={handleSearch} marginTop={20} marginBottom={10}
                         placeholder="Par type, metal, pierre, ref..." height={35}/>

            {query.length > 1 && productSearch.length > 1 && <span>{productSearch.length} résultat(s)</span>}

          </SearchWrapper>
          {query.length > 2 && productSearch.length < 1 && <span style={{textAlign: "center"}}>Aucun résultat trouvé</span>}
          <SearchResultWrapper>
            {productSearch.length > 0 && renderItem(productSearch)}
            {productSearch.length < 1 && renderItem(productList)}
          </SearchResultWrapper>
        </>
      ) : (
        <EditProductForm
          listCollections={listCollections}
          editProduct={handleEditProduct}
          successRedirection={handleRedirection}
          productSelected={productSelected}/>
      )}
    </MainWrapper>
  )
}
