import React from "react"

import { Separation } from "../../styles"
import { Label, PreviewImage, PreviewImageVariation } from "../CreateProductForm/styles"
import { DeleteButton, VariationCard, VariationDesc, TitleProduct } from "./StyledProductDisplay"
import { Heading, Pane } from "evergreen-ui"
import styled from "@emotion/styled"
import { AiOutlineDelete } from "react-icons/ai"

export default ({ listProducts, deleteItem }) => {

  console.info("PREVIEW LIST PRODUCT: ", listProducts)

  return (
    <Pane background="white" padding={24} marginBottom={24} marginLeft={30}>
      <Pane
        display="flex" justifyContent="flex-start"
      >
        <Heading fontFamily={"Lato"} fontSize={"17px"} fontWeight={400} is="h3">Liste des variations</Heading>
      </Pane>
      <Separation/>
      <Pane flex={1}>
        {listProducts.length > 0 ?(
          listProducts.map((product, id) => (
            <div key={product.id}>
              {id !== 0 && <Separation />}
              <VariationCard>
                <DeleteButton onClick={(e) => deleteItem(e, product)}>
                  <AiOutlineDelete size={16} />
                </DeleteButton>

                {product.preview.length > 0 && (
                  <PreviewImageVariation
                    src={product.preview[0].preview}
                  />
                )}

                <Pane marginLeft={"20px"}>
                  <TitleProduct>{product.attributeType} {product.attributeMetal} {product.attributeGem}</TitleProduct>
                  <VariationDesc>Collection: {product.collection}</VariationDesc>
                  {product.price === "0" ? (
                    <VariationDesc>Prix sur demande</VariationDesc>
                  ) : (
                    <VariationDesc>Prix {product.priceStartFrom && "à partir de"}: {product.price}</VariationDesc>
                  )}

                </Pane>
              </VariationCard>
            </div>
          ))) : ( <Label size={500}>Vous n'avez pas encore ajouté de variation</Label>)}
      </Pane>
    </Pane>
  )
}
