import { useDispatch, useSelector } from "react-redux"

// Actions
import {
  actionAddVariation,
  actionClearListProducts,
  actionDeleteVariation,
  actionSendProductSuccess
} from "../store/actions/admin"
import {
  apiGetFeaturedCollection,
  deleteDynamoDBCollection,
  deleteDynamoDBProduct,
  getAllCollections,
  getAllMegaMenu,
  getAllProducts,
  getAllSlides,
  updateDynamoDBCollections, updateDynamoDBFeaturedCollection,
  updateDynamoDBMegaMenu,
  updateDynamoDBSlider
} from "../utils/API"

const useAdmin = () => {
  const dispatch = useDispatch()

  const addVariation = product => {
    return new Promise(resolve => {
      dispatch(actionAddVariation(product))
      let res = {...product}
      res.images = []
      res.preview = false
      resolve(res)
    })
  }

  const sendProductSuccess = () => {
    dispatch(actionSendProductSuccess())
  }

  const clearListProducts = product => {
    dispatch(actionClearListProducts(product))
  }

  const deleteProduct = (product) => {
    return new Promise((resolve, reject) => {
      deleteDynamoDBProduct(product)
        .then(() => resolve())
        .catch(err => reject(err))
    })
  }

  const deleteVariation = (product) => {
    let newList = listProducts.filter(item => item.id !== product.id)
    dispatch(actionDeleteVariation(newList))
  }

  const getAllProduct = async() => {
    let res = await getAllProducts()
    let allProducts = res.data.listProducts.items;
    let allCollections = await getCollections();

    return allProducts.map(product => {
      let collectionFind = allCollections.find(collection => collection.id === product.collection)
      product.collection = collectionFind ? collectionFind.data.name : "Sans collection"
      return product
    })
  }

  const getAllProductId = async() => {
    let res = await getAllProducts()
    return res.data.listProducts.items
  }

  const getProductByCollection = async(collection) => {
    let res = await getAllProductId()
    return res.filter(product => product.collection === collection)
  }

  const updateSliderList = async(listSlides, newList) => {
    return new Promise((resolve, reject) => {
      let data = {
        ...listSlides,
        data: JSON.stringify(newList)
      }
      updateDynamoDBSlider(data).then(() => resolve(data)).catch(err => reject(err))
    })
  }

  const updateCollection = async(collection) => {
    return new Promise((resolve, reject) => {
      let data = {
        ...collection,
        data: JSON.stringify(collection)
      }
      updateDynamoDBCollections(data).then(() => resolve(data)).catch(err => reject(err))
    })
  }

  const updateMegaMenu = async(megaMenu) => {
    return new Promise((resolve, reject) => {
      let newMenu = megaMenu.map(menu => {
        menu.data = JSON.stringify(menu.data)
        return menu
      })
      const allPromises = []
      newMenu.forEach(menu => allPromises.push(updateDynamoDBMegaMenu(menu)))
      Promise.all(allPromises)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  const updateHomePage = (listBlock) => {
    return new Promise((resolve, reject) => {
      let newBlock = []
      listBlock.forEach(block => {
        let newItem = {...block}
        newItem.data = JSON.stringify(newItem.data)
        newBlock.push(newItem)
      })
      const allPromises = []
      newBlock.forEach(block => allPromises.push(updateDynamoDBFeaturedCollection(block)))
      Promise.all(allPromises)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  const getSlides = async() => {
    let res = await getAllSlides()
    return res.data.listDataSite.items[0]
  }

  const getCollections = async() => {
    let res = await getAllCollections()

    if (res.data.listDataSite.items.length > 0) {
      return res.data.listDataSite.items.map(item => item.data = JSON.parse(item.data))
    }
    else return []
  }

  const getMegaMenu = async() => {
    let res = await getAllMegaMenu()
    if (res.data.listDataSite.items.length > 0) {
      return res.data.listDataSite.items.map(item => {
        item.data = JSON.parse(item.data)
        return item
      })
    }
    else return []
  }

  const getBlockHome = async() => {
    let res = await apiGetFeaturedCollection()

   /* let test = {
      id: "86c15b12-6058-46cd-a83c-9a59e7d3f262",
      title: "Collection mise en avant",
      description: "Avec l’éclat de cet amour impossible, je lui déclare une flamme incandescente et passionnelle.\\n\\nSans limite et sans retenue, elle embrase son corps et son esprit, fous de ne pouvoir le retenir.",
      collection: "bf0fd86e-a9fa-4a1c-a850-b070e9242709",
      listProducts: [
        {
          id: "cddc571c-250b-4a51-a9e3-ce8497b19af7",
        },
        {
          id: "cddc571c-250b-4a51-a9e3-ce8497b19af7",
        },
        {
          id: "cddc571c-250b-4a51-a9e3-ce8497b19af7",
        },
        {
          id: "cddc571c-250b-4a51-a9e3-ce8497b19af7",
        },
        {
          id: "cddc571c-250b-4a51-a9e3-ce8497b19af7",
        },
      ]
    }

    console.log("STING", JSON.stringify(test))*/

    if (res.data.listDataSite.items.length > 0) {
      return res.data.listDataSite.items.map(item => {
        item.data = JSON.parse(item.data)
        return item
      })
    }
    else return []
  }

  const deleteCollection = (product) => {
    return new Promise((resolve, reject) => {
      deleteDynamoDBCollection(product)
        .then(() => resolve())
        .catch(err => reject(err))
    })
  }

  const listProducts = useSelector(state => state.admin.listProducts)

  const listSlider = useSelector(state => state.admin.listSlider)

  const isSuccess = useSelector(state => state.admin.isSuccess)

  return {
    addVariation,
    sendProductSuccess,
    clearListProducts,
    isSuccess,
    listProducts,
    listSlider,
    getAllProduct,
    updateHomePage,
    getSlides,
    getBlockHome,
    getProductByCollection,
    getCollections,
    getMegaMenu,
    updateCollection,
    updateMegaMenu,
    deleteVariation,
    deleteProduct,
    updateSliderList,
    deleteCollection
  }
}

export default useAdmin
