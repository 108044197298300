import React, { useState } from "react"
import { Storage } from "aws-amplify"
import { uuid } from "uuidv4"

import state from "./state"

import {
  Pane,
  Textarea,
  Autocomplete,
  TextInput,
  FilePicker,
  Button,
  Spinner,
  Heading,
  Checkbox
} from "evergreen-ui"

import { Label, PreviewImage } from "./styles"
import { Separation } from "../../styles"

import {
  initialProduct,
  collectionsList,
  attributeTypeList,
  attributeGemList,
  attributeMetalList,
  attributeSize, attributeGenre
} from "../../shared/ProductMetadata"
import {
  ButtonRemoveImage,
  WrapperInput,
  WrapperListPreviewImage,
  WrapperPreviewImage
} from "../CreateProductForm/styles"
import { MdRemoveCircle } from "react-icons/md"
import useAdmin from "../../../hooks/useAdmin"


export default ({ productSelected, editProduct, listCollections }) => {
  const { currentProduct, setProduct, isError, setError } = state({...productSelected})
  const [listImages, setListImages] = useState(productSelected.images)
  /*const listImages = process.env.IMAGE_ENDPOINT + productSelected.images[0].id + "_SMALL.png"*/
  const [clearInput] = useState(Date.now)
  const [loading, setLoading] = useState(false)
  const [imageLoading, setImageLoading] = useState(false)
  const [singleGem, setSingleGem] = useState(currentProduct.singleGem)

  const checkFormError = () => {
    let error = false
    for (let [key, value] of Object.entries(currentProduct)) {
      if (value === "") {
        error = {
          ...error,
          [key]: "empty"
        }
      }
    }
    return error
  }

  const handleChange = (name, value, checked) => {
    setProduct({
      ...currentProduct,
      [name]: value || checked
    })

    if (isError[name]) {
      setError({
        ...isError,
        [name]: false
      })
    }
  }

  const handleEditProduct = (e) => {
    e.preventDefault()
    setLoading(true)
    let error = checkFormError()
    if (!error) {
      editProduct({
        ...currentProduct,
        images: listImages.map(image => ({id: image.id})),
        singleGem,
      })
    } else {
      setError(error)
      setLoading(false)
    }
  }

  const uploadImage = async(source) => {
    if (source.length > 0) {
      setImageLoading(true)
      const fileName = uuid()
      const file = source[0]
      const urlImage = URL.createObjectURL(file)
      Storage.put(`${fileName}.png`, file, {
        contentType: "image/png"
      })
        .then(() => {
          setListImages([
            ...listImages,
            {
              id: fileName,
              preview: urlImage
            }
          ])
        } )
        .then(() => setImageLoading(false))
        .catch(err => console.error("S3 Error: ", err))
    }
  }

  const handleRemoveImage = (e, idImage) => {
    e.preventDefault()
    let newListImages = listImages.filter(image => image.id !== idImage)
    setListImages(newListImages)
  }

  return (
    <div>
      <Pane
        background="white"
        width={"630px"}
        padding={24}
        marginBottom={24}
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
      >
        <Pane display="flex" justifyContent="flex-start">
          <Heading fontFamily={"Muli"} fontSize={"17px"} fontWeight={400} is="h3">Modification d'un bijoux
          </Heading>
        </Pane>
        <Separation/>
        <Pane key={clearInput}>
          {/*ROW 1*/}
          <Pane
            display="flex"
            justifyContent="space-between"
            marginBottom={"15px"}
          >
            {/*PRODUCT TYPE*/}
            <Pane>
              <Label>Type de produit</Label>
              <Autocomplete
                title="Liste des types"
                onChange={e => handleChange("attributeType", e)}
                items={attributeTypeList()}
                initialSelectedItem={currentProduct.attributeType}
              >
                {(props) => {
                  const { getInputProps, getRef, inputValue, openMenu } = props
                  return (
                    <TextInput
                      isInvalid={!!isError.attributeType}
                      placeholder="Sélectionner..."
                      value={currentProduct.attributeType}
                      innerRef={getRef}
                      {...getInputProps({
                        onFocus: () => {
                          openMenu()
                        }
                      })}
                    />
                  )
                }}
              </Autocomplete>
            </Pane>
            {/*PRODUCT COLLECTION*/}
            <Pane>
              <Label>Collection</Label>
              <Autocomplete
                title="Liste des collections"
                onChange={e => handleChange("collection", e)}
                items={listCollections.map(item => item.data.name)}
                initialSelectedItem={currentProduct.collection}
              >
                {(props) => {
                  const { getInputProps, getRef, inputValue, openMenu } = props
                  return (
                    <TextInput
                      isInvalid={!!isError.collection}
                      placeholder="Sélectionner..."
                      value={currentProduct.collection}
                      innerRef={getRef}
                      {...getInputProps({
                        onFocus: () => {
                          openMenu()
                        }
                      })}
                    />
                  )
                }}
              </Autocomplete>
            </Pane>
          </Pane>
          {/*ROW 2*/}
          <Pane
            display="flex"
            justifyContent="space-between"
            marginBottom={"15px"}
          >
            {/*PRODUCT METAL*/}
            <Pane>
              <Label>Métal</Label>
              <Autocomplete
                title="Type de métal"
                onChange={e => handleChange("attributeMetal", e)}
                items={attributeMetalList()}
                initialSelectedItem={currentProduct.attributeMetal}
              >
                {(props) => {
                  const { getInputProps, getRef, inputValue, openMenu } = props
                  return (
                    <TextInput
                      isInvalid={!!isError.attributeMetal}
                      placeholder="Sélectionner..."
                      value={currentProduct.attributeMetal}
                      innerRef={getRef}
                      {...getInputProps({
                        onFocus: () => {
                          openMenu()
                        }
                      })}
                    />
                  )
                }}
              </Autocomplete>
            </Pane>
            {/*PRODUCT GEM*/}
            <Pane>
              <div>
                <Label>Pierre</Label>
                <Autocomplete
                  title="Type de pierre"
                  onChange={e => handleChange("attributeGem", e)}
                  items={attributeGemList()}
                  initialSelectedItem={currentProduct.attributeGem}
                >
                  {(props) => {
                    const { getInputProps, getRef, inputValue, openMenu } = props
                    return (
                      <TextInput
                        isInvalid={!!isError.attributeGem}
                        placeholder="Sélectionner..."
                        value={currentProduct.attributeMetal}
                        innerRef={getRef}
                        {...getInputProps({
                          onFocus: () => {
                            openMenu()
                          }
                        })}
                      />
                    )
                  }}
                </Autocomplete>
              </div>
            </Pane>
          </Pane>
          {/*PRODUCT GEM WEIGHT*/}
          {currentProduct.attributeGem === "Diamants" &&
          <Pane display={"flex"} justifyContent={"space-between"} marginBottom={"15px"}>
            <Pane>
              <Label>Poids du Diamant</Label>
              <Checkbox
                label="Autoriser le choix du poids"
                checked={singleGem}
                onChange={e => setSingleGem(e.target.checked)}
              />
            </Pane>
          </Pane>
          }

          {/*DESCRIPTION*/}
          <Label>Description du produit</Label>
          <Textarea
            id="description-area"
            value={currentProduct.description}
            onChange={(e) => handleChange("description", e.target.value)}
            name="description"
            isInvalid={!!isError.description}
            marginBottom={"10px"}
          />

          <Pane
            display="flex"
            justifyContent="space-between"
            marginBottom={"15px"}
          >
            <Pane>
              <Label>Genre</Label>
              <Autocomplete
                title="Genre"
                onChange={e => handleChange("genre", e)}
                items={attributeGenre()}
                initialSelectedItem={currentProduct.genre}
              >
                {(props) => {
                  const { getInputProps, getRef, inputValue, openMenu } = props
                  return (
                    <TextInput
                      isInvalid={!!isError.genre}
                      placeholder="Sélectionner..."
                      value={currentProduct.genre}
                      innerRef={getRef}
                      {...getInputProps({
                        onFocus: () => {
                          openMenu()
                        }
                      })}
                    />
                  )
                }}
              </Autocomplete>
            </Pane>
            <Pane>
              <Label>Référence</Label>
              <TextInput
                onChange={e => handleChange("ref", e.target.value)}
                value={currentProduct.ref || ""}
              />
            </Pane>

          </Pane>

          {/*PRICE & REF*/}
          <Pane
            display="flex"
            justifyContent="space-between"
            marginBottom={"15px"}
          >
            {/*REF*/}
            <Pane>
              <Label>Prix (0 = prix sur demande)</Label>
              <TextInput
                onChange={e => handleChange("price", e.target.value)}
                value={currentProduct.price}
              />
            </Pane>

            {currentProduct.price > 0 && (
              <WrapperInput >
                <Label className={"marginR"}>Afficher le prix "à partir de" </Label>
                <Checkbox
                  checked={currentProduct.priceStartFrom}
                  onChange={e => handleChange("priceStartFrom", e.target.checked)}
                />
              </WrapperInput>
            )}
          </Pane>

          {/*IMAGE PREVIEW*/}
          <Pane display="flex" justifyContent="space-between" marginBottom={"30px"}>
            <Pane>
              <Label>Image du produit</Label>
              <FilePicker
                width={250}
                marginBottom={32}
                onChange={file => uploadImage(file)}
                placeholder="Choisir une image"
              />
            </Pane>
            {/*{imageLoading && <Spinner marginY={32} marginX="auto" size={32}/>}
            {currentProduct.preview ? <PreviewImage
              src={currentProduct.preview}
            /> : <PreviewImage
              src={imagePreview}
            />}*/}
          </Pane>
          <WrapperListPreviewImage>
            {listImages.length > 0 && listImages.map(urlPreview => (
              <WrapperPreviewImage key={urlPreview.id}>
                <PreviewImage src={urlPreview.preview ? (urlPreview.preview) : (
                  process.env.IMAGE_ENDPOINT + urlPreview.id + "_SMALL.png"
                )}/>
                <ButtonRemoveImage onClick={(e) => handleRemoveImage(e, urlPreview.id)}>
                  <MdRemoveCircle size={16}/>
                </ButtonRemoveImage>
              </WrapperPreviewImage>
            ))}
            {imageLoading && <Spinner size={32}/>}
          </WrapperListPreviewImage>


          <Pane display="flex" justifyContent="flex-end">
            <Button
              isLoading={!!loading}
              height={40} marginRight={12} iconBefore="tick"
              onClick={handleEditProduct}
              disabled={currentProduct.images.length < 1 || imageLoading}
            >
              Valider le produit
            </Button>
          </Pane>
        </Pane>
      </Pane>
    </div>
  )
}
