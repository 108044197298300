import useAdmin from '../../../hooks/useAdmin'

export default () => {
  const { addVariation } = useAdmin()
  const { sendProductSuccess } = useAdmin()

  return {
    addVariation,
    sendProductSuccess
  }
}
